<template>
  <v-container class="pa-0">
    <v-dialog :key="selectInspectorDialogComponentKey" v-model="dialog" fullscreen hide-overlay>
      <kurcc-inspections-select-inspector-dialog-content :inspection-ids="inspectionIds" @change="$emit('change')" @close-dialog="close"/>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'KurccInspectionSelectInspectorDialog',
  components: {
    KurccInspectionsSelectInspectorDialogContent: () => import('@/modules/inspections/components/KurccInspectionsSelectInspectorDialogContent')
  },
  props: {
    inspectionIds: {
      type: Array,
      required: true
    },
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selectInspectorDialogComponentKey: 0
    }
  },
  computed: {
    dialog: {
      get () {
        return this.showDialog
      },
      set (v) {
        this.$emit('update:show-dialog', v)
      }
    }
  },
  methods: {
    close () {
      this.refreshDialogComponent()
      this.dialog = false
    },
    refreshDialogComponent () {
      this.selectInspectorDialogComponentKey++
    }
  }
}
</script>
